
// import { filterInputUpdate, filterNodesUpdate } from "./chunk/filter.js";


require('./chunk/offcanvas.js');
require('./chunk/img_SnapHeightToLine.js');
import "./chunk/matomo";




[/Edg/, /Edge/].forEach( (name) => {
  if (name.test(navigator.userAgent)) {
    document.documentElement.className += " edge";
    // alert('Hello Microsoft User!');
    console.log(navigator.userAgent);
  }
})


