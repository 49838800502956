export default (() => {
  const body = document.querySelector("body");
  const offcanvas_show_class = "offcanvas_show";
  const overlay = document.querySelector(".offcanvas_overlay");
  const burgerMenu = document.querySelectorAll(".burger_menu");

  const close = () => {
    body.classList.remove(offcanvas_show_class);
    document.removeEventListener("keydown", escKey);
  }
  const open = () => {
    body.classList.add(offcanvas_show_class);
    document.addEventListener("keydown", escKey);
  }
  const  escKey = (e) => {
    if (e.keyCode === 27) {
      close();
    }
  }

  
  overlay.addEventListener("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
    close();
  });

 


  burgerMenu.forEach(element => {

    element.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      body.classList.contains(offcanvas_show_class) ? close() : open();
    });
    /* add Active to mene-burger, when ready */
    element.classList.add("active");
  });
  
})();