
onResize
import {
  onResize
} from './_utils.js';

export default (() => {
  const imgElements = document.querySelectorAll(".image, .gallery")



  /**
   * line-height: 1.5
   * font-size: 16px * 1.5 = 24px
   * font-size: 18px * 1.5 = 27px
   * 
   */
  const set_imageHeight = () => {
    let lineHeight = window.innerWidth < 1600 ? 24 : 27 


    imgElements.forEach(container => {
      // const newContainerHeight = Math.ceil(container.getBoundingClientRect().height / lineHeight) * lineHeight;
      const newContainerHeight = Math.ceil(container.offsetHeight / lineHeight) * lineHeight;
      // console.log("Bild");
      // console.log("offsetHeight", container.offsetHeight);
      // console.log("getBoundingClientRect", container.getBoundingClientRect().height);
      // console.log("newContainerHeight", newContainerHeight);
      
      container.setAttribute('style',`min-height:${newContainerHeight}px`);
    });
  }

  set_imageHeight()
  onResize(set_imageHeight)

})();